/** Form Control  **/
input,
textarea {
    @apply border-gray-600;
}

input.error,
input.error:focus,
textarea.error {
    @apply border-red-600;
}

input.success,
textarea.success {
    @apply border-green-500;
}

/***/

/** hintText **/
.hintText,
textarea {
    @apply border-gray-600;
}

.hintText.error {
    @apply border-red-600;
}

.hintTextGroup:focus-within .hintText {
    @apply border-black;
}

.hintTextGroup:focus-within .hintText.error {
    @apply border-black;
}

.hintText.success {
    @apply border-green-500;
}

input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}

input[type=date]::-webkit-calendar-picker-indicator {
    /* -webkit-appearance: none;
    display: none; */
}


/** RADIO **/

input[type="radio"]+label span.radio-dot {
    @apply transition;
    @apply duration-200;
}

input[type="radio"]+label span.radio-text {
    @apply text-sm;
    @apply pt-0.5;
    @apply pl-1;
    @apply text-gray-650;
}

input[type="radio"]:checked+label span.radio-dot {
    @apply bg-primary-500;
    box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked+label span.radio-text {
    @apply text-primary-500;
    @apply font-bold;
}

input.input-number-hide-steps[type="number"] {
    -moz-appearance: textfield;
}

input.input-number-hide-steps[type="number"]::-webkit-outer-spin-button,
input.input-number-hide-steps[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}