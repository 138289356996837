@import "react-datepicker/dist/react-datepicker.css";
@import "./variables.css";
@import "./fontface.css";
@import "tailwindcss/base";
/* @import "./custom-base-styles.css"; */

@import "tailwindcss/components";
@import "./custom-components.css";

@import "tailwindcss/utilities";
/* @import "./custom-utilities.css";*/

html {
    scroll-behavior: smooth;
    color: #222222;
}