.homeShape::before {
    /* Fill 30 */
    content: " ";
    position: absolute;
    background-image: url(/images/shapes/home-light.svg);
    background-repeat: no-repeat;
    background-position-x: right;
    /* background-position-y: top; */
    background-size: min(90%, 750px);
    right: 0;
    top: 0;
    width: 100%;
    height: 260px;
    z-index: -100;
}

@screen md {
    .homeShape::before {
        background-image: url(/images/shapes/home.svg);
        height: 700px;
    }

    .homeShape.homeShapeMobile::before {
        display: none;
    }
}


.homeShape.homeShapeMobile::before {
    background-size: 160px;
    top: -70px;
}


.comingSoonShape::before {
    content: " ";
    position: absolute;
    background-image: url(/images/shapes/home-gradient.svg);
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: 90%;
    /* left: 0px; */
    top: 0px;
    width: 100%;
    height: 700px;
    z-index: -100;
}

@screen md {
    .comingSoonShape::before {
        background-position-x: left;
        background-size: 100%;
        left: 40%;
        top: 0;
        width: 60%;
        min-height: 700px;
        height: 100%;
    }
}

@screen 3xl {
    .comingSoonShape::before {
        width: 50%;
        left: 50%;
    }
}


.shapeA {
    background-image: url(/images/shapes/shape-a.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 90%;
}

.shapeB {
    background-image: url(/images/shapes/shape-b.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 90%;
}

.shapeC {
    background-image: url(/images/shapes/shape-c.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 90%;
}

.shapeHeader::before {
    content: "";
    position: absolute;
    z-index: -90;
    top: 13px;
    left: -100px;
    width: 418px;
    height: 425px;
    background-image: url(/images/shapes/shape-header.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
}

.subtitleUnderline {
    background-image: url(/images/shapes/lines/blue-underline.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: 15px;
    background-position-x: center;
}