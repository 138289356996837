.btn {
    @apply block;
    @apply py-3;
    @apply px-12;
    @apply border;
    @apply border-gray-600;
    @apply bg-white;
    @apply text-black;
    @apply rounded;
    @apply transition-colors;
    @apply duration-200;
}

.btn:focus {
    @apply outline-none !important;
}
.btn:focus {
    @apply border-black !important;
}

.btn:hover {
    @apply bg-gray-300;
    @apply border-black;
    @apply text-black;
}

.btn:disabled {
    @apply bg-gray-100;
    @apply text-gray-650;
    @apply border-gray-300;
}
.btn:disabled:hover {
    @apply bg-gray-100;
    @apply text-gray-650;
    @apply cursor-not-allowed;
}

.btn.active {
    @apply bg-gray-300;
    @apply border-black;
    @apply text-black;
    @apply font-bold;
}

.btn.error {
    @apply border-red-600;
}

/* .btn.success.active {
    @apply border-green-500;
} */
.btn.primary {
    @apply bg-primary-500;
    @apply border-primary-500;
    @apply text-white;
}
.btn.primary:focus {
    @apply border-primary-500 !important;
}
.btn.primary:hover {
    @apply bg-primary-600;
    @apply border-primary-600;
}
.btn.primary:disabled:hover {
    @apply bg-primary-500;
    @apply border-primary-500;
    @apply text-white;
    @apply cursor-default;
}


.btn.light {
    @apply bg-primary-100;
    @apply border-primary-500;
    @apply text-primary-500;
}
.btn.light:focus {
    @apply border-primary-500 !important;
}
.btn.light:hover {
    @apply bg-primary-200;
    @apply text-primary-600;
    @apply border-primary-600;
}
.btn.light:disabled:hover {
    @apply bg-primary-100;
    @apply border-primary-500;
    @apply text-white;
    @apply cursor-default;
}


.btn.outline {
    @apply bg-white;
    @apply border-black;
    @apply text-black;
    @apply border-2;
}
.btn.outline:focus {
    @apply border-black !important;
}
.btn.outline:hover {
    @apply bg-gray-300;
    @apply border-black;
    @apply text-black;
}
.btn.outline:disabled:hover {
    @apply bg-gray-300;
    @apply border-gray-650;
    @apply text-gray-650;
    @apply cursor-default;
}



/** Button group */

.btn-group {
    @apply w-full;
    @apply flex;
    @apply items-stretch;
}

.btn-group.horizontal {
    @apply flex-row;
}

.btn-group.vertical {
    @apply flex-col;
}

.btn-group .btn {
    @apply w-full;
    @apply py-6;
}