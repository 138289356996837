@font-face {
    font-family: 'Nunito Sans';
    font-weight: 300;
    src: url('/fonts/Nunito_Sans/NunitoSans-Light.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    font-weight: 400;
    src: url('/fonts/Nunito_Sans/NunitoSans-Regular.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    font-weight: 700;
    src: url('/fonts/Nunito_Sans/NunitoSans-Bold.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    font-weight: 800;
    src: url('/fonts/Nunito_Sans/NunitoSans-ExtraBold.ttf');
    font-display: swap;
}