.formPanel {
    @apply w-full;
    @apply shadow-sm;
    @apply bg-white;
    @apply border;
    @apply border-gray-400;
    @apply rounded-lg;
    @apply mb-8;
    @apply px-4;
    @apply pt-10;
    @apply pb-4;
}

.formPanel-sm {
    @apply px-4;
    @apply pt-8;
    @apply pb-4;
}

@screen lg {
    .formPanel {
        @apply px-16;
        @apply pt-16;
        @apply pb-6;
        @apply shadow-lg;
    }
    .formPanel-sm {
        @apply px-8;
        @apply pt-10;
        @apply pb-6;
    }
    
}

.formPanel_title {
    @apply mb-8 text-xl font-bold text-center;
}

.formPanel_subtitle {
    @apply w-2/3 mx-auto mb-8 text-base text-center text-gray-600;
}


