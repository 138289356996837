/** Form Control **/

.select {
    @apply border-gray-600;
}
.select.error {
    @apply border-red-600;
}
.select.success {
    @apply border-green-500;
}